import SmoothScroll from './smooth-scroll.js'

var scroll = new SmoothScroll('nav a[href*="#"]');
const mobilenav = document.querySelector('.mobilenav')
const burger_and_close = document.querySelectorAll('.hamburger--icon')

for (var i = burger_and_close.length - 1; i >= 0; i--) {
  burger_and_close[i].addEventListener('click', function() {
   mobilenav.classList.toggle('open')
  }, false);
}

/**
 * Smooth Scroll events
 */
document.addEventListener('scrollStart', function(e) {
  if ( mobilenav.classList.contains('open') ) {
    mobilenav.classList.toggle('open')
  }
}, false);
